export const variants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 25 }
}

export const transition = {
  ease: 'easeOut',
  duration: 0.75,
  delay: 0.15
}

export const threshold = {
  threshold: 0.05
}
