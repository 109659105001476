import React from 'react'

const ExternalButton = ({ title, link, icon = false, className }) => {
  return (
    <a
      className={`${className} flex items-center justify-center py-3 px-4 bg-primary rounded-full text-white text-base hover:bg-gray-300 hover:text-primary`}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={title}
      href={link}
    >
      {title}
      {icon}
    </a>
  )
}
export default ExternalButton
