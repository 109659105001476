import React, { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { variants, transition, threshold } from '../constants'

const Div = ({ className, children }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView(threshold)

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  )
}
export default Div
