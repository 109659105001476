import React from 'react'

import { motion } from 'framer-motion'
import { Toggle } from './Toggle'

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: 'circle(30px at 40px 40px)',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40
    }
  }
}

const MobileMenu = ({ isOpen, toggleOpen, height, containerRef }) => {
  return (
    <motion.div
      className="flex flex-col lg:hidden"
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={height}
      ref={containerRef}
    >
      <Toggle toggle={() => toggleOpen()} />
    </motion.div>
  )
}
export default MobileMenu
