import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Meta from './Meta'
import NavBar from '../components/NavBar/NavBar'
import Footer from '../components/Footer/Footer'
import Contact from '../components/Contact/Contact'
import SimpleReactLightbox from 'simple-react-lightbox'

import { ModalProvider } from 'react-simple-hook-modal'
import 'react-simple-hook-modal/dist/styles.css'
import './Layout.css'

export default ({ children, meta, title }) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            googleTrackingId
            socialMediaCard {
              image
            }
          }
        }
      `}
      render={data => {
        const {
          siteTitle,
          siteDescription,
          socialMediaCard,
          googleTrackingId
        } = data.settingsYaml || {}
        // subNav = {
        // posts: data.allPosts.hasOwnProperty('edges')
        //   ? data.allPosts.edges.map(post => {
        //       return { ...post.node.fields, ...post.node.frontmatter }
        //     })
        //   : false
        // }

        return (
          <Fragment>
            <Helmet
              defaultTitle={siteTitle}
              titleTemplate={`%s | ${siteTitle}`}
            >
              {title}
              <meta name="description" content={siteDescription} />
              <html lang="pt-BR"></html>
              <link href="https://ucarecdn.com" rel="preconnect" crossOrigin />
              <link rel="dns-prefetch" href="https://ucarecdn.com" />
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin
              />
              <link
                href="https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap"
                rel="stylesheet"
              />
              {/* Add font link tags here */}
            </Helmet>

            <Meta
              googleTrackingId={googleTrackingId}
              absoluteImageUrl={
                socialMediaCard &&
                socialMediaCard.image &&
                socialMediaCard.image
              }
              {...meta}
              {...data.settingsYaml}
            />
            <NavBar />
            <ModalProvider>
              <SimpleReactLightbox>
                <Fragment>{children}</Fragment>
              </SimpleReactLightbox>
            </ModalProvider>
            <Contact />
            <Footer />
          </Fragment>
        )
      }}
    />
  )
}
