import React from 'react'
import { motion } from 'framer-motion'
import { Navigation } from './Navigation'

const MenuContent = ({ isOpen, height, containerRef, links }) => {
  return (
    <motion.div
      className="flex mt-5 justify-center items-center lg:hidden"
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={height}
      ref={containerRef}
    >
      <Navigation isOpen={isOpen} links={links} />
    </motion.div>
  )
}
export default MenuContent
