import * as React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}

export const Item = ({ link }) => {
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      {link.isExternal && (
        <a
          href={link.value}
          target="_blank"
          className="text-base text-white text-center"
        >
          <p className="p-3">{link.name}</p>
        </a>
      )}
      {!link.isExternal && (
        <Link className="text-base text-white text-center" to={link.value}>
          <p className="p-3">{link.name}</p>
        </Link>
      )}
    </motion.li>
  )
}
