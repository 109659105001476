import React from 'react'
import Separator from '../Menu/Separator/Separator'
import { FaLaptopCode } from 'react-icons/fa'

const Footer = () => {
  return (
    <section className="bg-dark px-5">
      <div className="max-w-screen-xl mx-auto">
        <Separator style="solid" />
        <div className="mreaves flex text-tiny text-white justify-center items-center py-10">
          <p>© {new Date().getFullYear()} - desenvolvido por</p>
          <p className="ml-2">
            <b>
              <a
                href="https://www.linkedin.com/in/pedrokorb/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Linkedin Pedro"
                className="flex items-center"
              >
                Pedro Korb
                <FaLaptopCode className="h-8 w-8 ml-2" />
              </a>
            </b>
          </p>
        </div>
      </div>
    </section>
  )
}
export default Footer
